#top-nav {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 2;
    .rs-navbar-nav .rs-dropdown > .rs-dropdown-toggle{
        height: 60px;
    }
    .rs-navbar-header {
        padding: 5px 15px;
        height: 60px;
        white-space: nowrap;
        overflow: hidden;
        i {
            vertical-align: 0;
            font-size: 20px;
        }
    }
    .rs-nav-item {
        a {
            min-width: 60px;
            text-align: center;
        }
    }
    .logout {
        a {
            text-align: center;
            width: 60px;
            i {
                margin-right: 0;
            }
        }
    }
    .selected-account {
        padding: 16px;
    }
    .rs-nav-item-content {
        padding: 20px 16px;
        height: 60px;
    }
    .user-profile {
        .rs-dropdown-toggle {
            padding: 14px 16px;
        }
        .rs-dropdown-toggle-caret {
            display: none;
        }
        .initials {
            background: white;
            color: black;
            border-radius: 100%;
            padding: 7px 9px;
            font-size: 14px;
            font-weight: bold;
        }
    }
    .settings {
        .rs-dropdown-toggle-caret {
            display: none;
        }
        &.rs-dropdown > .rs-dropdown-toggle{
            padding: 18px 20px;
        }
    }
}

#menu {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    padding-top: 80px;

    .rs-nav-item > .rs-nav-item-content {
        color: #555;
    }
    .menu-items {
        height: calc(100vh - 115px);
        //background: transparent;
    }
    .rs-navbar {
        overflow: hidden;
    }
    .logout-icon {
        margin-right: 23px;
        margin-left: 6px;
    }

    .rs-picker-toggle-wrapper {
        width: 100%;
    }
}
