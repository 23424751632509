/*mobi scroll*/

$mbsc-ios-accent: $primary;
$mbsc-material-accent: $primary;
$mbsc-mobiscroll-accent: $primary;
$mbsc-ios-primary: $primary;
$mbsc-ios-secondary: $secondary;
$mbsc-ios-success: $success;
$mbsc-ios-danger: $danger;
$mbsc-ios-warning: $warning;
$mbsc-ios-info: $info;

@import "~@mobiscroll/react/dist/css/mobiscroll.scss";
//@import "~@mobiscroll/react_v4/dist/css/mobiscroll.scss";
//@.min.css

/*V4 overrides*/

.mbsc-material .mbsc-fr-w {
    background: #f7f7f7;
    color: #000000;
    border: 1px solid rgba(black, 0.2);
}
.mbsc-material .mbsc-cal-c {
    padding: 0;
}
.mbsc-cal-hdr {
    padding: 0.5em;
    color: $primary;
}
.mbsc-cal-scroll-c {
    background: white;
}
.mbsc-material .mbsc-fr-btn {
    color: $primary;
}
.mbsc-cal-cell {
    border: 1px solid rgba(black, 0.05);
}
.mbsc-material .mbsc-range-btn.mbsc-selected {
    border-color: $primary;
    color: $primary;
}
.mbsc-material.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-date {
    background: $primary;
    border-color: $primary;
}
.mbsc-material .mbsc-cal-today,
.mbsc-material .mbsc-cal-tab.mbsc-selected {
    color: $primary;
}
.mbsc-material.mbsc-range .mbsc-cal-day.mbsc-selected:after {
    background: rgba($primary, 0.1);
}
