body {
    font-size: 12px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-weight: bold;
}

.rs-input{
    font-size: 12px;
}

.rs-control-label{
    font-weight: bold;
}
