.status-badge {
    line-height: 1;
    padding: 2px 6px 3px;
    font-size: 10px;
    &.active {
        background-color: $success;
    }
    &.draft {
        background-color: gray;
    }
    &.disabled,
    &.canceled,
    &.declined {
        background-color: $danger;
    }
    &.pending {
        background-color: $warning;
    }
    &.rescheduled {
        background-color: $info;
    }
    &.archived {
        background-color: #3b3b3b;
    }
}
textarea.rs-input {
    min-width: 200px;
}
.rs-drawer {
    .rs-drawer-header {
        .rs-drawer-title {
            font-size: 18px;
        }
    }
    .drawer-subtitle {
        font-size: 10px;
    }
    .drawer-toptitle {
        font-size: 10px;
    }
    .rs-input-group-addon {
        font-size: 12px;
    }
}
.rs-picker-toggle.rs-btn-sm {
    font-size: 12px;
}
.rs-form-group {
    position: relative;
}
.inline-radio {
    .rs-radio-checker > label {
        font-size: 12px;
        padding: 5px 10px;
    }
    span {
        padding: 6px 6px 6px 10px;
        display: inline-block;
        vertical-align: middle;
        font-weight: bold;
    }
    .rs-radio-checked {
        label {
            font-weight: bold !important;
        }
    }
}
.rs-form-group {
    label {
        display: block;
    }
    .rs-form-control-wrapper {
        display: inline-block;
    }
}
.rs-divider-horizontal.rs-divider-with-text {
    //padding: 0 15px;
    .rs-divider-inner-text {
        font-weight: bold;
        font-size: 14px;
    }
    &:before,
    &:after {
        border-top: 1px solid $primary;
    }
}

.show-action-icons-hover {
    &.action-icons,
    .action-icons {
        .rs-btn-icon {
            transition: opacity 0.2s ease-in-out;
            opacity: 0;
        }
    }
    &:hover,
    &:focus {
        &.action-icons,
        .action-icons {
            .rs-btn-icon {
                opacity: 1;
            }
        }
    }
}

.rs-help-block-tooltip {
    margin-top: 7px;
    line-height: 1;
    opacity: 0.3;
}

.rs-input-group.rs-input-group-inside {
    .rs-input {
        padding-right: 50px;
    }
    .rs-input-group-addon {
        opacity: 0.5;
        padding: 7px 13px 8px 13px;
    }
    &.addon-right {
        .rs-input-group-addon {
            border-left: none;
            left: auto;
            right: 0;
        }
    }
    &.addon-left {
        .rs-input {
            padding-right: 5px;
            padding-left: 45px;
        }
    }
}
.rs-drawer-footer {
    text-align: left;
    padding-bottom: 20px;
}
.rs-table-cell {
    .rs-btn-link {
        font-size: 12px;
    }
}
.rs-tag {
    display: block;
}
.clickable {
    cursor: pointer;
}
.rs-table {
    z-index: 1;
}
