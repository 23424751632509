//bootstrap default overrides
$spacer: 2rem !default;

//boostrap colors overides -> there are also rsuite colors in rsuite.less
$primary: #003767 !default;
$secondary: #1a86cf !default;
$success: #1a9a55 !default;
$info: #1a86cf !default;
$warning: #f5c033 !default;
$danger: #d1370a !default;
$bg-gray: #FAFAFA !default;
