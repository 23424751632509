.accounts.rs-drawer {
    .rs-panel-heading {
        font-size: 14px;
        font-weight: bold;
        background: $bg-gray;
        padding: 10px 20px;
        &:before {
            top: 10px;
        }
        border-bottom: 1px solid rgba(black, 0.05);
    }
    .rs-panel-body {
        padding: 30px 20px;
    }
    .rs-panel {
        border-radius: 0;
    }

    .rs-panel-group.small-panel {
        .rs-panel {
            position: relative;
            .rs-form-group:not(:last-child) {
                margin-bottom: 5px;
            }
            .rs-panel-heading {
                font-size: 12px;
                padding: 8px 25px 20px;
                font-weight: bold;
                border-bottom: 0;
                background: transparent;
                &:before {
                    top: 10px;
                }
            }
            .rs-help-block-tooltip {
                margin-top: 5px;
            }
            .rs-panel-body {
                padding-top: 0;
                .rs-form-group {
                    position: relative;
                }
            }
            .inline-radio {
                vertical-align: top;
                span {
                    padding: 2px 2px 2px 6px;
                    font-weight: 400;
                }
                .radio-checked {
                    font-weight: bold;
                }
                label {
                    font-size: 12px;
                    padding: 3px 10px;
                }
            }
            .rs-form-control-wrapper > .rs-input {
                width: 200px;
            }
        }
        .delete-sub-item {
            position: absolute;
            right: 10px;
        }
    }
}
.custom-fields {
    .custom-field{
        position: relative;
    }
    .rs-tag-group > .rs-tag {
        margin: 0;
        display: block;
    }
    .remove-custom-field{
        position: absolute;
        top:0;
        right:10px;
    }
}
