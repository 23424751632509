.inventory {
    background-color: rgba(white, 0.6);

    .inventory-item {
        font-size: 14px;
        position: relative;
        display: inline-block;
        width: 250px;
        min-height: 300px;
        padding-bottom: 25px;
        .item-title {
            white-space: normal;
            text-align: left;
        }
        .status-badge {
            cursor: context-menu;
            position: absolute;
            top: 10px;
            right: 10px;
        }
        .action-bar {
            position: absolute;
            bottom: 10px;
            right: 0;
            left: 0;
            padding: 0 10px;
            width: 100%;
        }
        .rs-panel-heading {
            padding-bottom: 5px;
            padding-top: 30px;
        }
        .rs-progress-line {
            padding: 0;
            .rs-progress-info-status {
                font-size: 12px;
                line-height: 1.6;
            }
        }
        .item-type {
            position: absolute;
            top: 10px;
            left: 20px;
            font-size: 8px;
        }
        .current-availability {
            background-color: $primary;
            font-size: 10px;
        }
    }
}
.items.rs-drawer {
    .rs-panel-heading {
        font-size: 14px;
        font-weight: bold;
        background: $bg-gray;
        padding: 10px 20px;
        &:before {
            top: 10px;
        }
        border-bottom: 1px solid rgba(black, 0.05);
    }
    .rs-panel-body {
        padding: 30px 20px;
    }
    .rs-panel {
        border-radius: 0;
    }

    .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn-sm {
        padding-right: 55px;
    }
    .availability {
        position: relative;
        background: #fdfdfd;
        border: 1px solid rgba(black, 0.1);
        padding: 10px;
        border-radius: 6px;
        margin-bottom: 20px;
        .delete {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
    .availability-calendar {
        width: 70%;
        margin: auto;
    }
}

.code-snippet {
    pre {
        background: #f4f4f4;
        border: 1px solid #ddd;
        border-left: 3px solid #f36d33;
        color: #666;
        page-break-inside: avoid;
        font-family: monospace;
        font-size: 15px;
        line-height: 1.6;
        margin-bottom: 1.6em;
        max-width: 100%;
        overflow: auto;
        padding: 1em 1.5em;
        display: block;
        word-wrap: break-word;
    }
}

//calendar related
.cl-available-slots {
    position: absolute;
    right: 4px;
    top: 4px;
    font-size: 9px;
}
.mbsc-ios.mbsc-event-text {
    padding-top: 10px;
}
.mbsc-ios.mbsc-event.mbsc-list-item {
    padding: 1em !important;
}

//booking
.peoples-drawer {
    z-index: 1075;
}
